export const routePath = {
    home:"/",
    courseDetails:"/course/:courseName",
    aboutus:"/about-us",
    contactus:"/#contact",
    result:"/results",
    resoSat:"/resosat",
    whyResonance:"/why-resonance",
    whyResonanceIndore:"/why-resonance-indore",
    jee11th12th:"/11th + 12th -JEE Main + Advance",
    jee12th:"/12th-JEE Main + Advanced",
    droppersJEE:"/Droppers-JEE Main + Advance",
    neet11th12th:"/11th+12th-NEET",
    neet12th:"/12th-NEET",
    droppersNEET:"/Droppers-NEET",
    foundation8th:"/Foundation-08th",
    foundation9th:"/Foundation-09th",
    foundation10th:"/Foundation-10th",
    resotest:"http://resosat.resonanceindore.com/practice-test"

}