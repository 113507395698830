import React from "react";
import WhyImg from "../../src/assets/image/About resonance image 570X386.jpg";
import WhyImg2 from "../../src/assets/image/about 01.jpg";

const WhyResonanceInd = () => {
  return (
    <>
      <section className="mt-20 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-16">
        <div data-aos="zoom-out">
          <img src={WhyImg} alt="img" />
        </div>
        <div  data-aos="fade-right" className=" flex flex-col gap-5">
          <div className="text-4xl font-semibold ">
            Resonance Indore - Best IIT JEE/NEET Coaching
          </div>
          <div data-aos="fade-right" className="flex flex-col mt-4 gap-5 ">
            <p>Expert, Experienced & Trained faculty team from KOTA only.</p>
            <p>Result oriented attention for every student.</p>
            <p>
              Intense personal attention to every student with batch size of
              40-45 students only.
            </p>
            <p>Time to time Individual counselling session for motivation.</p>
            <p>Centralized course planner</p>
            <p>All India level competition benefits.</p>
          </div>
        </div>
      </section>

      <div  className="mt-20">
        <h3 data-aos="fade-left">
          Resonance Indore An Exemplary in the field of quality parallel
          education, which has set high standards for JEE – (Main + Advanced) &
          Pre - Medical preparation. Indore study centre is redefining what
          preparatory education is, the Faculty, the ethics, the Study Material,
          the Staff- all work relentlessly for optimising the output from a
          student, which is paramount for the assured success in the ferocious
          competition of IIT-JEE / NEET.
        </h3>
        <br />
        <h3 data-aos="fade-left">
          In a short span of time it has become a household name and Institute
          of choice for preparation of big competitive exams like JEE (Main &
          Advanced) and Pre-Medicals (NEET). There was a much felt need to give
          the students an edge at a younger age, to make children reign in the
          competitive world. With the vision to improve academics in young
          children, Resonance's Pre - foundation Career care program or PCCP was
          incepted, and there has been no looking back since then. There are
          arrays of performances, in both yearlong contact classroom program or
          YCCP and PCCP. Reso Indore is producing toppers from Indore in
          JEE-main or Advanced and giving Excellent Ratio of qualifiers for both
          IIT-JEE (Advanced) and NEET.
        </h3>
        <br />
        <h3 data-aos="fade-left">
          The exams like JEE (Main + Advanced) attracts huge number of
          applicants, and thus offers enormous competition in academics. The
          quality training and uncompromised approach to deliver the highest
          standards in studies, has earned the Institute the reputation of most
          sought after institute in the segment. Reso’s phenomenal growths in
          both quality & quantity of results speak volumes about our approach
          and excellence we impart. The glory has spread outside Indore and M.P.
          and students from far flung places also come to sharpen their skills
          and shape their future. The Journey so far has been very exciting and
          is evident from Leaps and Bounds growth both in selections and
          enrolments.
        </h3>
      </div>

      <section className="grid grid-cols-1 ">
        <h1 className="text-3xl font-semibold mt-20 text-center ">
          Teaching Methodology at {" "}
          <span className="gradient-text">Resonance Indore </span>
        </h1>

        <p className="mt-10">
          Resonance has a highly systematic and standardized system of teaching.
          Our study material, course planning, testing system, timetable,
          performance monitoring system etc. are all pre-defined and well set.
          All systems work according to these rules and deliver a uniform and
          high-quality learning experience to all students
        </p>
      </section>

      <section className="mt-20 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2"> 
        <div className="flex-1">
          <img className="w-[82%]" src={WhyImg2} alt="" />
        </div>
        <div className="flex-1 flex flex-col gap-3 ">
          <h2>Student Mentoring System Course & Lecture Planning</h2>
          <h2>Teaching Methodology</h2>
          <h2>Lecture Notes Preparation</h2>
          <h2>Faculty Productivity Management</h2>
          <h2>Study Material Development & Question Bank Management</h2>
          <h2>Test Paper Preparation</h2>
          <h2>Regular Class Management</h2>
          <h2>Student's Performance Management</h2>
          <h2>Academic Quality Control & Auditing</h2>
          <h2>Academic Research & Development</h2>
          <h2>Orientation & Motivation Classes</h2>
        </div>
      </section>


      <div className="mt-20 text-center">
        <h1>
          * We understand the importance of different components, tools, and
          techniques of training, which are necessary for results and we term
          this system as "The RESO Way"
        </h1>
      </div>



      <section> 
        <div className="mt-20 text-3xl font-semibold">
          Academic Team (Faculty)
        </div>
        <p className="mt-2">Teacher understands how to make you LEARN.</p>
       

      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 sm:gap-0 md:gap-40">
        <div id="left" className="mt-14 flex flex-col gap-8">
          <div>
            Regular Classes (RCs) <br />
            Fundamental learning through rigorous & enriched classroom
            experience.
          </div>

          <div>
            Daily Practice Problems (DPPs) <br />
            Daily Practice for strengthening the daily learning.
          </div>

          <div>
            Practice Booklet (Sheets)
            <br />
            Experience and knowledge in a well written form. More practice
            towards perfection.
          </div>

          <div>
            Doubt Classes (DCs)
            <br />
            For individual attention and problem solving.
          </div>

          <div>
            Periodic Assessment Tests (PATS)
            <br />
            Assessment through online && offline tests.
          </div>
        </div>

        <div id="right" className="mt-14 flex flex-col gap-8 ">
          <div>
            Academic Research & Development (R&D)
            <br />
            Developing new teaching aids and questions.
          </div>

          <div>
            Academic Administration and Management
            <br />
            Managing entire learning experience.
          </div>

          <div>
            Online Learning Platform
            <br />
            Facility of Online Classes & Recorded Lectures.
          </div>

          <div>
            Co-academic Arrangements
            <br />
            Timely support & stress free learning.
          </div>

          <div>
            Concept Building Classes.
            <br />
            Theory of certain important topics is taught after the course
            completion of Class XI to XII.
          </div>
        </div>
      </div>
      </section>
    </>
  );
};

export default WhyResonanceInd;
