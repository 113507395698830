import React from "react";
import logo from "../assets/image/Reso Logo Black.png";
import img1 from "../assets/whyRosonancePage-03.jpg";
import img2 from "../assets/whyRosonancePage-04.jpg";
import img3 from "../assets/whyRosonancePage-01.jpg";
import img4 from "../assets/whyRosonancePage-02.jpg"

const WhyResonancePage=()=> {
  return (
    <div>
      <section className="px-4 my-12 lg:px-0">
        <div className="flex flex-col sm:flex-row justify-center relative z-30 items-center gap-2 pt-4 sm:pt-16 text-center sm:text-left">
          <h1 className="font-bold text-xl sm:text-2xl">Why Choose</h1>
          <img   src={logo} alt="Logo" className="h-10  sm:h-12 w-auto" />
        </div>

        <div className="flex justify-center items-center animate_float ">
          <img src={img1} className="h-72 w-auto" />
        </div>
      </section>

      <section data-aos="zoom-out-up" data-aos-duration={400} className="px-4 lg:px-28 ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 text-center ">
          <div>
            <h3 className="font-bold text-lg mb-4">
              System-based Organization
            </h3>
            <ul className="list-disc list-inside space-y-2 text-left text-md ">
              <li>
                Leadership: Experienced, Competent, Qualified (IITian) &
                Academician
              </li>
              <li>
                Core Team: Group of Academicians (Mostly IITians) & able
                Administrators
              </li>
              <li>Defined Processes and Structured Roles & Responsibilities</li>
              <li>Planned Academic Management & Curriculum Execution</li>
              <li>Student-centric & Performance Oriented Approach</li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold text-lg mb-4">Teaching Methodology</h3>
            <ul className="list-disc list-inside space-y-2 text-left text-md">
              <li>
                Tried, Tested & Trusted Teaching Methodology of Kota System is
                followed
              </li>
              <li>
                Application-based Conceptual & Effective Classroom Teaching (For
                CCPs)
              </li>
              <li>
                Fundamental learning-based & well-researched Study Material with
                Comprehensive Syllabus coverage
              </li>
              <li>
                Personalized Doubt Removal Classes to enhance ‘individual’
                learning
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold text-lg mb-4">Learning Environment</h3>
            <ul className="list-disc list-inside space-y-2 text-left text-md">
              <li>Disciplined Execution of Course Planning & Syllabus</li>
              <li>
                Interactive Approach to promote & solve/clear the
                doubts/concepts
              </li>
              <li>
                Continuous Performance Evaluation & Remedial measures to improve
                performance
              </li>
              <li>Continuous Competitive Benchmarking at National-level</li>
              <li>
                Supportive co-academic environment makes the learning conducive
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section data-aos="zoom-out-up" data-aos-duration={600} className="px-4 py-8 lg:px-24">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center lg:pt-14">
          <div>
            <h1 className="text-xl sm:text-2xl font-bold mb-4">
              Preparatory Programmes for Various Examinations
            </h1>
            <ul className="list-disc list-inside space-y-2 text-gray-800">
              <li>Joint Entrance Examination JEE (MAIN + Advanced)</li>
              <li>Joint Entrance Examination JEE (MAIN)</li>
              <li>All India Pre-Medical Test (NEET (AIPMT))</li>
              <li>All India Institute Of Medical Science (AIIMS)</li>
              <li>
                Scholarship/Talent Search Examinations like NTSE, KVPY &
                Olympiads
              </li>
              <li>
                Other National/State level Engineering/Medical Entrance
                Examinations (along with JEE (Main + Advanced)/JEE (Main) &
                NEET(AIPMT)/AIIMS respectively)
              </li>
              <li>CA/CS, CLAT</li>
            </ul>
          </div>

          <div className="flex justify-center lg:justify-end">
            <img
              src={img2}
              alt="Preparatory Programmes"
              className=" animate_float w-full max-w-xs lg:max-w-md h-auto"
            />
          </div>
        </div>
      </section>
      <section data-aos="zoom-out-up" data-aos-duration={1000} className="px-4 py-8 lg:px-24">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center lg:pt-14 ">
          {/* Image Section */}
          <div className="flex justify-center lg:justify-start order-last md:order-first lg:order-first">
            <img
              src={img3}
              alt="Preparatory Programmes"
              className=" animate_float w-full max-w-xs lg:max-w-md h-auto"
            />
          </div>

          {/* Text Section */}
          <div>
            <h1 className="text-xl sm:text-2xl font-bold mb-4">
              Student-centric Academic Programmes/Courses
            </h1>
            <ul className="list-disc list-inside space-y-2 text-gray-800 ">
              <li>
                Classroom Contact Programmes (CCPs): Year-long, Short-term &
                fast-track Programmes for JEE (Main + Advanced), JEE (Main) &
                NEET (AIPMT)/AIIMS for the students of Classes - XI, XII & XII+
              </li>
              <li>
                Pre-foundation Career Care Programmes (PCCPs) for NTSE &
                Olympiads for the students of Classes - V to X
              </li>
              <li>
                Distance Learning Programmes (DLPs) for the students of Classes
                - VI to XII & XII+ for JEE (Main + Advanced), JEE (Main), NEET
                (AIPMT)/AIIMS & NTSE etc.
              </li>
              <li>
                e-Learning Programmes (eLPs) for the students of Classes - V to
                XII & XII+ for JEE (Main + Advanced), JEE (Main), NEET
                (AIPMT)/AIIMS & NTSE etc.
              </li>
              <li>
                Coverage of respective class Board Syllabus (mostly CBSE based
                on NCERT) apart from the preparation of Competitive Examinations
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="px-4 py-8 lg:px-24">
        <div data-aos="fade-right" className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center lg:pt-14">
          <div>
            <h1 className="text-xl sm:text-2xl font-bold mb-4">
            Student Welfare Programmes (SWPs)
            </h1>
            <ul className="list-disc list-inside space-y-2 text-gray-800 ">
              <li>Micro-Macro Level Performance Analysis & Communication</li>
              <li>Students Satisfaction Surveys (SSS) about Academic & Non- academics</li>
              <li>Parents-Teacher Meeting (PTM)/Parents-Resonance Meeting (PRM)</li>
              <li>Grievance Redressal, Assessment & Management System (GRAMS)</li>
              <li>
              Counseling (Career , Exams & Health) & Motivation Programmes (CMPs)
              </li>
             
            </ul>
          </div>

          <div className="flex justify-center lg:justify-end">
            <img
              src={img4}
              alt="Preparatory Programmes"
              className="animate_float w-full max-w-xs lg:max-w-md h-auto"
            />
          </div>
        </div>
      </section>

      <section className="px-4 py-8 lg:px-10">
        <div className="flex flex-col sm:flex-row justify-center items-center gap-2 pt-4 sm:pt-16 text-center sm:text-left">
          
          <img src={logo} alt="Logo" className="h-10 sm:h-12 w-auto" />
          <h1 className="font-bold text-xl sm:text-2xl">जैसा <span className="text-red-700"> कोई </span> नहीं!</h1>
        </div>
      </section>
    </div>
  );
}

export default WhyResonancePage;
