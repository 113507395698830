import { courseData } from "../data/CourseCardData";
import CourseCard from "../cards/CourseCard";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

const CourseCardSection = () => {
  const courseRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#course") {
      courseRef.current.scrollIntoView({ block: "start" });
    }
  }, [location]);

  return (
    <div className="mt-10" ref={courseRef} id="course">
      {/* Section Heading */}
      <h1
        data-aos="flip-left"
        data-aos-duration="1500"
        className="text-4xl font-bold text-center mb-8 px-1"
      >
        <span className="text-black">Resonance Indore</span>{" "}
        <span className="gradient-text">Popular Courses</span>
      </h1>

      {/* Responsive Grid Container */}
      <div className="container mx-auto lg:px-12 px-0 ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  ">
          {courseData.map((course, index) => (
            <Link
              key={index}
              data-aos="zoom-out-up" 
              data-aos-duration={index * 100 + 200}
              to={course.link}
            >
              <CourseCard
                key={course.courseName}
                link={course.link}
                courseName={course.courseName}
                poster={course.image}
                courseDescription={course.courseDescription}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseCardSection;
