import React from "react";
import { NEETImages } from "../../data/hallOfFameData";

const NEETResultSection = () => {
  return (
    <div>
    <div className="my-4 grid lg:grid-cols-4 xl:grid-cols-4 md:grid-cols-3 grid-cols-1  content-center gap-8 mx-auto w-11/12 h-fit px-4 py-2">
      {NEETImages.map((data) => (
        <div className="card bg-[#192f59] flex justify-center p-2 rounded-xl ">
          <div className="card-image object-cover aspect-square bg-[#192f59] py-2">
            <img loading="lazy" className="w-full h-full" src={`${data.image}`} alt="Ashhar Shahid" />
          </div>
          <div className="heading-card flex justify-center items-center font-bold text-center capitalize text-[#bad648]"> {data.name}<br/>{data.collage}</div>
        </div>
      ))}
    </div></div>
  );
};

export default NEETResultSection;
