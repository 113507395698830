import * as Image from "../data/ImageData";

export const CourseDetailData = [
  {
    image: Image.VIKAAS,
    name: "11th-course-jee",
    courseName: "COURSE NAME “VIKAAS” (11th Going)",
    targetexam: "Target Exam - JEE MAIN & ADVANCED",
    heading:"VIKAAS is a specially designed course which perfectly prepares students for JEE Main and Advanced in span of two years. Two options available in “VIKAAS” batches:",
    jeetbatch1: "1 “JEET” Batch (Morning to Evening Batch)",
    desc1:"Class Timings - 9.00 AM to 5.40 PM In this batch, 9.00 AM to 2.00 PM, there are classes of Physics, Chemistry and Maths. 2.00 PM to 2.30 PM lunch-break, then 2.30 PM to 5.40 PM, we are providing daily practice sessions under the monitoring of teachers.This batch is specially designed by experienced educators after doing research on all psychological requirements of the students.In this batch, with very comfortable timings, student can best able to manage learning and practice sessions.",
    vikaasbatch2:"2 Evening “VIKAAS” Batch - Class Timings – 4.00 PM to 8.00 PM",
    desc2: "In this batch, there are classes of Physics, Chemistry and Maths.",
    formData: {
      FacultyTeam: "Expert, Experienced and Energetic Team from Kota only.",
      Batchsize1: "Max 40 to 50 Students per Batch.",

      Batchsize2: "Max 40 to 50 Students per Batch.",

      Batchsize3: "Max 40 to 50 Students per Batch.",

      Test: "Weekly Test, Phase Test, Cumulative Test and ALL India Test.",

      DoubtClasses: "Daily individual doubt clearing sessions by same faculty.",

      StudyMaterial:
        "Topic Wise Well designed Material, Module, DPP’s, Question Bank, Practice Paper, and Home Assignment.",

      Library:
        "Additional Other Reference Books, Question Bank, Practice Paper also Available.",

      SchoolAdmissionFacility: "Available for both CBSE and MP Board Schools",

      MedicalFacility:
        "Available with reputed Hospitals and Doctors near to coaching institute to support students in case of any medical requirements or emergency.",

      HostelFacility: "Available nearby Institute.",

      Counseling: "Time to time individual counseling and motivation sessions.",
    },
    formDataSecond: {
      CourseFee:
        "– Rs. 169000/- Only (Morning to Evening Batch) including GST.– Rs. 120000 Only (Evening Batch) Including GST.",
      FeePaymentMode: "– UPI, Cash, Cheque",
    },
  },

  {
    image: Image.VISHWAAS,
    name: "12th-course-jee",
    courseName: "COURSE NAME “VISHWAAS” (12th Going)",
    targetexam: "Target Exam - JEE MAIN & ADVANCED",
    heading:
      "VISHWAAS is specially designed course for 12th going students, which perfectly prepare students for JEE Main and Advanced in span of one year. There is Two Options Available in Vishwaas Batch :",
    jeetbatch1: "1 “JEET” Batch (Morning to Evening Batch)",
    desc1:
      "Class Timings - 9.00 AM to 5.40 PM (In this 9.00 AM to 2.00PM there is Classes of Physics , Chemistry and Maths. 2.00 to 2.30 PM Lunch Break, then 2.30 to 5.40 PM we are providing Daily Practice Sessions under the monitoring of Teachers.)This Batch is specially designed by Experienced Educators after doing Research on all Psychological Requirements of students. In this Batch Students are very comfortably able to Manage Their Learning and Practice Time.",
    vikaasbatch2:
      "2 Evening “VISHWAAS” Batch - Class Timings – 4.00 PM to 8.00 PM",

    formData: {
      FacultyTeam: "Expert, Experienced and Energetic Team from Kota only.",
      Batchsize: "Max 40 to 50 Students per Batch.",

      Test: "Weekly Test, Phase Test, Cumulative Test and ALL India Test.",

      DoubtClasses: "Daily individual doubt clearing sessions by same faculty.",

      StudyMaterial:
        "Topic Wise Well designed Material, Module, DPP’s, Question Bank, Practice Paper, and Home Assignment.",

      Library:
        "Additional Other Reference Books, Question Bank, Practice Paper also Available.",

      SchoolAdmissionFacility: "Available for both CBSE and MP Board Schools",

      MedicalFacility:
        "Available with reputed Hospitals and Doctors near to coaching institute to support students in case of any medical requirements or emergency.",

      HostelFacility: "Available nearby Institute.",

      Counseling: "Time to time individual counseling and motivation sessions.",
    },
    formDataSecond: {
      CourseFee:
        "– Rs. 169000/- Only (Morning to Evening Batch) including GST.– Rs. 120000 Only (Evening Batch) Including GST.",
      FeePaymentMode: "– UPI, Cash, Cheque",
      RegistrationFormFee: "– 500/-Rs.Only",
    },
  },

  {
    image: Image.VIJAY,
    name: "Dropper-jee-mains-advance",
    courseName: "COURSE NAME “VIJAY” (12th PASS) Dropper Batch",
    targetexam: "Target Exam - JEE MAIN & ADVANCED -2025",
    heading:
      "VIJAY is specially designed course for 12th Pass students,which perfectly prepare students for JEE Main and Advanced in span of one year.Unique Feature of Resonance Indore’s VIJAY Batch is Intense Personal Attention with All India Level Competitions.",

    formData: {
      ClassTimings: "9.00 AM to 2.00PM .",

      SelfStudyZone:
        "from 2:30 to 9:00 PM Student can do self-study at Center.",

      FacultyTeam: "Expert, Experienced and Energetic Team from Kota only.",

      Batchsize: "Max 40 to 50 Students per Batch.",

      Test: "Weekly Test, Phase Test, Cumulative Test and ALL India Test.",

      DoubtClasses: "Daily individual doubt clearing sessions by same faculty.",

      StudyMaterial:
        "Topic Wise Well designed Material, Module, DPP’s, Question Bank, Practice Paper, and Home Assignment.",

      Medium: "Available in Both English and Hindi by Same Expert Teachers.",

      Library:
        "Additional Other Reference Books, Question Bank, Practice Paper also Available.",

      MedicalFacility:
        "Available with reputed Hospitals and Doctors near to coaching institute to support students in case of any medical requirements or emergency.",

      HostelFacility: "Available nearby Institute.",

      Counseling: "Time to time individual counseling and motivation sessions.",
    },
    formDataSecond: {
      CourseFee: "– Rs. 158000/- Only",
      FeePaymentMode: "– UPI, Cash, Cheque",
      RegistrationFormFee: "– 500/-Rs.Only",
    },
  },
  {
    image: Image.SAKSHAM,
    name: "11th-neet",
    courseName: "COURSE NAME “SAKSHAM” (11th Going)",
    targetexam: "Target Exam - NEET:2026",
    heading:
      "SAKSHAM is specially designed course for 11th going students,which perfectly prepare students for NEET - UG in span of two years. Two options available in “SAKSHAM” Course",
    jeetbatch1: "1 “Saksham Morning Batch” (Morning to Evening Batch)",
    desc1:
      "Class Timings - 9.00 AM to 5.40 PM In this batch, 9.00 AM to 2.00 PM, there are classes of Physics, Chemistry and Maths. 2.00 PM to 2.30 PM lunch-break, then 2.30 PM to 5.40 PM, we are providing daily practice sessions under the monitoring of teachers. This batch is specially designed by experienced educators after doing research on all psychological requirements of the students. In this batch, with very comfortable timings, student can best able to manage learning and practice sessions.",
    vikaasbatch2:
      "Evening “Saksham” Batch - Class Timings – 4.00 PM to 8.00 PM",

    formData: {
      FacultyTeam: "Expert, Experienced and Energetic Team from Kota only.",

      Batchsize: "Max 40 to 50 Students per Batch.",

      Test: "Weekly Test, Phase Test, Cumulative Test and ALL India Test.",

      DoubtClasses: "Daily individual doubt clearing sessions by same faculty.",

      StudyMaterial:
        "Topic Wise Well designed Material, Module, DPP’s, Question Bank, Practice Paper, and Home Assignment.",

      Library:
        "Additional Other Reference Books, Question Bank, Practice Paper also Available.",

      SchoolAdmissionFacility: "Available for both CBSE and MP Board Schools",

      MedicalFacility:
        "Available with reputed Hospitals and Doctors near to coaching institute to support students in case of any medical requirements or emergency.",

      HostelFacility: "Available nearby Institute.",

      Counseling: "Time to time individual counseling and motivation sessions.",
    },
    formDataSecond: {
      CourseFee:
        "	– Rs. 135000/- Only (Morning to Evening Batch) including GST.– Rs. 120000 Only (Evening Batch) Including GST.",
      FeePaymentMode: "– UPI, Cash, Cheque",
      RegistrationFormFee: "– 500/-Rs.Only",
    },
  },
  {
    image: Image.SAMBHAV,
    name: "12th-neet",
    courseName: "COURSE NAME “SAMBHAV” (12th Going) ONE YEAR",
    targetexam: "Target Exam -NEET 2025",
    heading:
      "SAMBHAVis specially designed course for 12th going students, which perfectly prepare students for NEET in span of one year.Unique Feature of Resonance Indore’s SAMBHAV Batch is Intense Personal Attention with All India Level Competitions.",

    formData: {
      FacultyTeam: "Expert, Experienced and Energetic Team from Kota only.",

      SelfStudyZone:
        "from 2:30 to 9:00 PM Student can do self-study at Center.",

      Batchsize: "Max 40 to 50 Students per Batch.",

      Test: "Weekly Test, Phase Test, Cumulative Test and ALL India Test.",

      DoubtClasses: "Daily individual doubt clearing sessions by same faculty.",

      StudyMaterial:
        "Topic Wise Well designed Material, Module, DPP’s, Question Bank, Practice Paper, and Home Assignment.",

      Library:
        "Additional Other Reference Books, Question Bank, Practice Paper also Available.",

      SchoolAdmissionFacility: "Available for both CBSE and MP Board Schools",

      MedicalFacility:
        "Available with reputed Hospitals and Doctors near to coaching institute to support students in case of any medical requirements or emergency.",

      HostelFacility: "Available nearby Institute.",

      Counseling: "Time to time individual counseling and motivation sessions.",
    },
    formDataSecond: {
      CourseFee: "– Rs. 120000/- Only.",
      FeePaymentMode: "– UPI, Cash, Cheque",
      RegistrationFormFee: "– 500/-Rs.Only",
    },
  },
  {
    image: Image.SAMPOORN,
    name: "dropper-neet",
    courseName: "COURSE NAME “SAMPOORN” (12th PASS) Dropper Batch",
    targetexam: "Target Exam - NEET -2025",
    heading:
      "SAMPOORN is specially designed course for 12th Pass students, which perfectly prepare students for NEET in span of one year.Unique Feature of Resonance Indore’s SAMPOORN Batch is Intense Personal Attention with All India Level Competitions.",

    formData: {
      ClassTimings: "9.00 AM to 2.00PM .",

      SelfStudyZone:
        "from 2:30 to 9:00 PM Student can do self-study at Center.",

      FacultyTeam: "Expert, Experienced and Energetic Team from Kota only.",

      Batchsize: "Max 40 to 50 Students per Batch.",

      Test: "Weekly Test, Phase Test, Cumulative Test and ALL India Test.",

      DoubtClasses: "Daily individual doubt clearing sessions by same faculty.",

      StudyMaterial:
        "Topic Wise Well designed Material, Module, DPP’s, Question Bank, Practice Paper, and Home Assignment.",

      Medium: "Available in Both English and Hindi by Same Expert Teachers.",

      Library:
        "Additional Other Reference Books, Question Bank, Practice Paper also Available.",

      MedicalFacility:
        "Available with reputed Hospitals and Doctors near to coaching institute to support students in case of any medical requirements or emergency.",

      HostelFacility: "Available nearby Institute.",

      Counseling: "Time to time individual counseling and motivation sessions.",
    },
    formDataSecond: {
      CourseFee: "– Rs. 120000/- Only",
      FeePaymentMode: "– UPI, Cash, Cheque",
      RegistrationFormFee: "– 500/-Rs.Only",
    },
  },
  {
    image: Image.PIONEER,
    name: "foundation-9th",
    courseName: "COURSE NAME “PIONEER” (9th Going)",
    targetexam:
      "Target - NCERT, Board, Foundation of Competitive Exams like JEE and NEET",
    heading:
      "PIONEER Course is designed for IX going students, with an aim to build conceptual framework for the students to tackle their school examinations & competitive examinations which are organized at National & International Level like IJSO (International Junior Science Olympiad), PRMO (Pre-Regional Mathematical Olympiad), NMTC (National Mathematics Talent Contests) etc.The Course help students to take early lead, prepare them in gradual and systematic manner for high level competitive exams :",
    jeetbatch1:
      "Batches Available in Evening Time Class Timings- 5.00 PM to 8.00PMClass Frequency- 3 to 4 days in Week",

    formData: {
      FacultyTeam: "Expert, Experienced and Energetic Team from Kota only.",

      Batchsize: "Max 25 to 30 Students per Batch.",

      SubjectsCovered:
        "Science (Phy., Che., Bio.), Social Science, Maths, Mental Ability & English .",

      Test: "Weekly Test, Phase Test, Cumulative Test and ALL India Test.",

      DoubtClasses: "Daily individual doubt clearing sessions by same faculty.",

      StudyMaterial:
        "Topic Wise Well designed Material, Module, DPP’s, Question Bank, Practice Paper, and Home Assignment.",

      Library:
        "Additional Other Reference Books, Question Bank, Practice Paper also Available.",

      HomeWorkDiscussion:
        "After classroom teaching, homework is given to students, which is discussed in the next class of that particular subject.",

      MotivationalSessions:
        "There is provision of motivational classes within the curriculum which is required to keep the students on track and motivate them to aspire for success.",
    },
    formDataSecond: {
      CourseFee: "– Rs. 60000/- Only",
      FeePaymentMode: "– UPI, Cash, Cheque",
      RegistrationFormFee: "– 300/-Rs.Only",
    },
  },
  {
    image: Image.PINNACLE,
    name: "foundation-10th",
    courseName: "COURSE NAME “PINNACLE” (10th Going)",
    targetexam:
      "Target - NCERT, Board, Foundation of Competitive Exams like JEE and NEET",
    heading:
      "PINNACLE Course is designed for X going students, with an aim to build conceptual framework for the students to tackle their school examinations & competitive examinations which are organized at National & International Level like NTSE,IJSO (International Junior Science Olympiad), PRMO (Pre-Regional Mathematical Olympiad), NMTC (National Mathematics Talent Contests) etc. This Course help students to take early lead, prepare them in gradual and systematic manner for high level competitive exams :",
    jeetbatch1:
      "Batches Available in Evening TimeClass Timings- 5.00 PM to 8.00PMClass Frequency- 3 to 4 days in Week",

    formData: {
      FacultyTeam: "Expert, Experienced and Energetic Team from Kota only.",

      Batchsize: "Max 25 to 30 Students per Batch.",

      SubjectsCovered:
        "Science (Phy., Che., Bio.), Social Science, Maths, Mental Ability & English .",

      Test: "Weekly Test, Phase Test, Cumulative Test and ALL India Test.",

      DoubtClasses: "Daily individual doubt clearing sessions by same faculty.",

      StudyMaterial:
        "Topic Wise Well designed Material, Module, DPP’s, Question Bank, Practice Paper, and Home Assignment.",

      Library:
        "Additional Other Reference Books, Question Bank, Practice Paper also Available.",

      HomeWorkDiscussion:
        "After classroom teaching, homework is given to students, which is discussed in the next class of that particular subject.",

      MotivationalSessions:
        "There is provision of motivational classes within the curriculum which is required to keep the students on track and motivate them to aspire for success.",
    },
    formDataSecond: {
      CourseFee: "– Rs. 60000/- Only",
      FeePaymentMode: "– UPI, Cash, Cheque",
      RegistrationFormFee: "– 300/-Rs.Only",
    },
  },
];
