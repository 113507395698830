export const HallOfFameImages = [
    "/ResoResult/Akshat_Choudhary.png",
    "/ResoResult/Anishmesh Sharma.png",
    "/ResoResult/Bakul Jangley.png",
    "/ResoResult/Devansh Joshi.png",
    "/ResoResult/Ginisha Garg.png",
    "/ResoResult/Gracy Shivhare.png",
    "/ResoResult/Ishan Jain.png",
    "/ResoResult/Lokesh Korsane.png",
    "/ResoResult/Mahi Mittal.png",
    "/ResoResult/Manthan Gupta.png",
    "/ResoResult/Mradul Joshi.png",
    "/ResoResult/Muskan Jaiswal.png",
    "/ResoResult/Naveen Patel.png",
    "/ResoResult/Nikhil Choudhary.png",
    "/ResoResult/Nishikant Parmar.png",
    "/ResoResult/Parth Pugalia.png",
    "/ResoResult/Prince Nagar.png",
    "/ResoResult/Purnanand.png",
    "/ResoResult/Purvi Verma.png",
    "/ResoResult/Rhythm Badge.png",
    "/ResoResult/Ronak Bandwal.png",
    "/ResoResult/Rudraksh Khatekar.png",
    "/ResoResult/Saksham Soni.png",
    "/ResoResult/Samarth Sirsat.png",
    "/ResoResult/Sammarth Kapse.png",
    "/ResoResult/Sarthak Kapse.png",
    "/ResoResult/Shantanu C.png",
    "/ResoResult/Sheetal Nagar.png",
    "/ResoResult/Shivam Gami.png",
    "/ResoResult/Shivendra Singh.png",
    "/ResoResult/Shubha Dhami.png",
    "/ResoResult/Sourabh Khare.png",
    "/ResoResult/Tanishq Sakhare.png",
    "/ResoResult/Urvashi Godhane.png",
    "/ResoResult/Vachika Gupta.png",
    "/ResoResult/Vansh Singhal.png",
    "/ResoResult/Vikas Patidar.png",
  ];
  export const NEETImages = [
    {
      image: "./NEET/Ashhar Shahid - HMCH Jharkhand.png",
      name: "Ashhar Shahid",
      collage: "HMCH Jharkhand",
    },
    {
      image: "./NEET/Jagriti Nayak - GMGC Bhopal.png",
      name: "Jagriti Nayak",
      collage: "GMGC Bhopal",
    },
    {
      image: "./NEET/Saurabh Kumar - Govt. MDVMC.png",
      name: "Saurabh Kumar",
      collage: "Govt. MDVMC",
    },
    {
      image: "./NEET/Shweta Bharti - GMGC Bhopal.png",
      name: "Shweta Bharti",
      collage: "GMGC Bhopal",
    },
  
    {
      image: "./NEET/Sujal Nagar - MGM College Indore.png",
      name: "Sujal Nagar",
      collage: "MGM College Indore",
    },
    {
      image: "./NEET/Tanisha Solanki - Gove. BMC Sagar.png",
      name: "Tanisha Solanki",
      collage: "Gove. BMC Sagar",
    },
    {
      image: "./NEET/vedika_jajo-MGM Collage Indore.png",
      name: "vedika_jaju",
      collage: "MGM Collage Indore",
    },
  ];
  