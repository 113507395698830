import React from "react";
import first from "../assets/image/lifeAt/individual counsling session.webp";
import second from "../assets/image/lifeAt/children's day celebration.webp";
import third from "../assets/image/lifeAt/Celebrating Festivals.webp";
import fourth from "../assets/image/lifeAt/Picnic Day.webp";

const PhotoLibrary = () => {
  return (
    <>
      <div>
        <h1 className="text-3xl font-bold text-center mb-8">
          Life At <span className="gradient-text"> Resonance</span>
        </h1>
      </div>

      {/* Row Layout for First and Second Images */}
      <div className="flex flex-col sm:flex-row items-center justify-center gap-2 px-4 md:px-12 lg:px-28">
        <div data-aos="flip-right" data-aos-duration="1000" className="w-full sm:w-[60%] md:w-[65%] p-2">
          <img
            className="w-full h-[300px] md:h-[350px] rounded-xl object-cover"
            src={first}
            alt="Individual Counseling Session"
          />
        </div>
        <div  data-aos="flip-right" data-aos-duration="1000" className="w-full sm:w-[40%] md:w-[35%] p-2">
          <img
            className="w-full h-[300px] md:h-[350px] rounded-xl object-cover"
            src={second}
            alt="Children's Day Celebration"
          />
        </div>
      </div>

      {/* Grid Layout for Third and Fourth Images */}
      <div className="flex flex-col sm:flex-row items-center justify-center gap-2 px-4 md:px-12 lg:px-28">
        
        <div  data-aos="flip-left" data-aos-duration="1000" className="w-full sm:w-[40%] md:w-[35%] p-2">
          <img
            className="w-full h-[300px] md:h-[350px] rounded-xl object-cover"
            src={third}
            alt="Children's Day Celebration"
          />
        </div>
        <div  data-aos="flip-left" data-aos-duration="1000" className="w-full sm:w-[60%] md:w-[65%] p-2">
          <img
            className="w-full h-[300px] md:h-[350px] rounded-xl object-cover"
            src={fourth}
            alt="Individual Counseling Session"
          />
        </div>
      </div>
    </>
  );
};

export default PhotoLibrary;
