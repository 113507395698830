import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import circle from "../assets/image/Why choose reso image 400X375 - Copy.jpg";


const Button = ({btnTitle}) => {
  return (
    <button className="px-6 py-3 sm:py-4 mt-8 sm:mt-12 font-semibold bg-[#192F59] rounded-2xl text-white flex items-center gap-2 hover:bg-[#1a396e] font-poppins ">
     {btnTitle ?btnTitle:"Get Started"}
    <FaArrowRightLong />
    </button>
  )
}

export default Button


