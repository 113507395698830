import step1 from "../assets/how to new/1.png";
import step2 from "../assets/how to new/2.png";
import step3 from "../assets/how to new/3.png";
import step4 from "../assets/how to new/4.png";
import step5 from "../assets/how to new/5.png";
import step6 from "../assets/how to new/6.png";
export const HowToRegister = [
  {
    step: "Fill the registration form",
    description:
      "Register by entering your mobile number and press submit button",
    screenshot: step1,
  },
  {
    step: "Verify OTP",
    description: " You will receive an OTP on given mobile number",
    screenshot: step2,
  },
  {
    step: "Fill out the Enroll form and Enroll for resoSAT exam",
    description:
      "After OTP verification . you will see an enrollment form . Fill up the form with correct details and press submit",
    // note: "Only one participant can register with one mobile number (online mode exam) also make sure given mobile number is with you as your userId and Password will be sent on that number",
    screenshot: step3,
  },
  {
    step: "Receiving loginId and password and success message",
    description:
      "After submitting form you will receive your login password and login link via SMS",
    screenshot: step4,
  },
  {
    step: "Login to Resosat portal",
    description:
      "Login with userId and password you received on sms",
    screenshot: step5,
  },
  {
    step: "start test",
    description:
      "After login your selected test will be visible to you . You can start test by clicking on it.\n A instruction window will be opened and on clicking start test you can begin scholarship assisment test ",
    note: "Test link will only open on test date , you can only attain the test on given date(received on sms) , student can give test at any time on the scheduled date",
    screenshot: step6,
  },
];
