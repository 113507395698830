export const ExamDates = {
  online: [
    "07-dec-2024",
    "2024-12-08",
    "2024-12-09",
    "2024-12-10",
    "2024-12-11",
    "2024-12-12",
    "2024-12-13",
    "2024-12-14",
    "2024-12-15",
    "2024-12-16",
    "2024-12-17",
    "2024-12-18",
    "2024-12-19",
    "2024-12-20",
  ],
  offline: [{ value: "2024-12-01" }],
};
