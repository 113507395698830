import React from "react";
import { CourseDetailData } from "../data/CourseDetailData";
import { useParams } from "react-router-dom";
import { courseData } from "../data/CourseCardData";
import Button from "../components/Button";

const CourseDetailPage = () => {
  const { courseName } = useParams();

  let selected = CourseDetailData.find((i) => i.name === courseName);
  console.log("selected", selected);

  function getTable() {
    let table = [];
    for (let key in selected.formData) {
      let tableRow = (
        <tr data-aos="fade-right" className="odd:bg-gray-200">
          <th className="border-2 border-gray-400 py-5">{key}</th>
          <td className="border-2 border-gray-400 py-5 px-2">
            {selected.formData[key]}
          </td>
        </tr>
      );
      table.push(tableRow);
    }
    return table;
  }

  return (
    <>
      <div className="lg:px-44 px-10 font-poppins pt-16 lg:pt-0 lg:my-2 my-10">
        <div className="lg:flex mb-12 lg:flex-row flex-col-reverse flex">
          <div id="left" data-aos="fade-right" data-aos-duration="600" className=" lg:w-2/3 w-full">
            <div className="">
              <h1 className="font-bold lg:text-3xl text-xl lg:mt-20 mt-5">
                {selected?.courseName}
              </h1>
            </div>

            <div>
              <p className="y-2 lg:mt-8 mt-4 font-regular lg:text-xl text-lg">
                {selected?.name}
              </p>
            </div>

            <div>
              <h2 className=" text-zinc-400 font-medium  lg:mt-6 mt-2 ">
                {selected?.heading}
              </h2>
            </div>

            <Button btnTitle={"Enroll now"} />
          </div>

          <img
            className="lg:w-[400px] w-full lg:m-auto mx-auto shadow-xl rounded-lg border-2 border-gray-300 float-end bg-red-300 "
            src={selected?.image}
            data-aos="flip-left"
            data-aos-duration="600"
            alt="img"
          />
        </div>

        <div data-aos="fade-up" className="p-4 my-10">
          <p className="text-start">
            {" "}
            <b>{selected?.jeetbatch1}</b>
            <br />
            <br />
            {selected?.desc1}
          </p>
          <p className="text-start mt-4">
            <b>
              {" "}
              {selected?.vikaasbatch2}
            </b>
            <br />
            {selected?.desc2}
          </p>
        </div>

        <div id="table">
          <table data-aos="fade-down" className="border-2 w-10/12 mx-auto border-gray-500 border-collapse">
            {getTable()?.map((i) => i)}
          </table>
        </div>
      </div>
    </>
  );
};

export default CourseDetailPage;
