import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination,EffectFade } from "swiper/modules";
import 'swiper/css/effect-fade';
import "swiper/css";
import banner1 from "../assets/image/carouselImages/Website Banner-1.jpg";
import banner2 from "../assets/image/carouselImages/Website Banner-4.jpg";
import bannner2_O from "../assets/image/carouselImages/Website Banner-2.jpg";
import bannner3_O from "../assets/image/carouselImages/Website Banner-3.jpg";
import banner3 from "../assets/image/carouselImages/Website Banner-first.jpg";
import banner4 from "../assets/image/carouselImages/website banner-second.jpg";
import banner5 from "../assets/image/carouselImages/website Banner-third.jpg";

const sliderImages = [
  banner1,
  banner2,
  bannner2_O,
  bannner3_O,
  banner3,
  banner4,
  banner5,
  //   "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/f19216118883485.6092425f52680.jpg",
  //   "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/0b212f118883485.6092425f50616.jpg",
  //   "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e45584118883485.6092425f51822.jpg",
  //   "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e45584118883485.6092425f51822.jpg",
];

function Slider() {
  return (
    <>
      <Swiper
      effect="fade"
        style={{ background: "#192f59" }}
        className="mySwiper border-none rounded-xl "
        slidesPerView={1}
        spaceBetween={32}
        modules={[Autoplay, Pagination,EffectFade]}
        loop={true}
        centeredSlides={true}
        speed={1500}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 32,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 32,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 32,
          },
        }}
      >
        {sliderImages.map((item) => (
          <SwiperSlide className="h-full w-full">
            <img width={"100%"} height={"100%"} src={item} alt="slider" />
            {/* <div
              style={{
                background: `url(${item})`,
                backgroundPosition: "center",
                width:"100vw",
                height: "100vh",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            ></div> */}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
export default Slider;
