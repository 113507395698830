import moment from "moment";

export const timeCheck = (previousDate) => {
  const currentDate = new Date().getTime();
  previousDate = new Date(previousDate).getTime();
  let matchesCurrentDate = false;
  if (previousDate > currentDate) {
    matchesCurrentDate = true;
  }
  return matchesCurrentDate;
};

export const timeConverter = (date) => {
  return moment(date).format("ll");
};
